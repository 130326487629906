import type { Component, ComponentProps, JSX, ValidComponent } from 'solid-js';
import { Show, splitProps } from 'solid-js';

import * as SheetPrimitive from '@kobalte/core/dialog';
import type { PolymorphicProps } from '@kobalte/core/polymorphic';
import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/www/lib/utils';

const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.CloseButton;

const portalVariants = cva('z-50 flex', {
  variants: {
    position: {
      top: 'items-start',
      bottom: 'items-end',
      left: 'justify-start',
      right: 'justify-end',
    },
  },
  defaultVariants: { position: 'right' },
});

type PortalProps = SheetPrimitive.DialogPortalProps & VariantProps<typeof portalVariants>;

const SheetPortal: Component<PortalProps> = (props) => {
  const [local, others] = splitProps(props, ['position', 'children']);
  return (
    <SheetPrimitive.Portal {...others}>
      <div class={portalVariants({ position: local.position })}>{local.children}</div>
    </SheetPrimitive.Portal>
  );
};

type DialogOverlayProps<T extends ValidComponent = 'div'> = SheetPrimitive.DialogOverlayProps<T> & {
  class?: string | undefined;
};

const SheetOverlay = <T extends ValidComponent = 'div'>(
  props: PolymorphicProps<T, DialogOverlayProps<T>>,
) => {
  const [local, others] = splitProps(props as DialogOverlayProps, ['class']);
  return (
    <SheetPrimitive.Overlay
      class={cn(
        'data-closed:fade-out-0 data-expanded:fade-in-0 fixed inset-0 z-50 bg-black/80 data-closed:animate-out data-expanded:animate-in',
        local.class,
      )}
      {...others}
    />
  );
};

const sheetVariants = cva(
  'fixed z-50 gap-4 bg-background shadow-lg transition ease-in-out data-closed:duration-300 data-expanded:duration-500 data-expanded:animate-in data-closed:animate-out',
  {
    variants: {
      position: {
        top: 'inset-x-0 top-0 border-b data-closed:slide-out-to-top data-expanded:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-closed:slide-out-to-bottom data-expanded:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-closed:slide-out-to-left data-expanded:slide-in-from-left sm:max-w-sm',
        right:
          'inset-y-0 right-0 h-full w-3/4 border-l data-closed:slide-out-to-right data-expanded:slide-in-from-right sm:max-w-sm',
      },
    },
    defaultVariants: {
      position: 'right',
    },
  },
);

type DialogContentProps<T extends ValidComponent = 'div'> = SheetPrimitive.DialogContentProps<T> &
  VariantProps<typeof sheetVariants> & {
    class?: string | undefined;
    children?: JSX.Element;
    overlay?: boolean;
    defaultCloseButton?: boolean;
  };

const SheetContent = <T extends ValidComponent = 'div'>(
  props: PolymorphicProps<T, DialogContentProps<T>>,
) => {
  const [local, others] = splitProps(props as DialogContentProps, [
    'position',
    'class',
    'children',
    'overlay',
    'defaultCloseButton',
  ]);
  return (
    <SheetPortal position={local.position}>
      <Show when={local.overlay ?? true}>
        <SheetOverlay />
      </Show>
      <SheetPrimitive.Content
        class={cn(sheetVariants({ position: local.position }), local.class)}
        {...others}
      >
        {local.children}
        <Show when={local.defaultCloseButton ?? true}>
          <SheetPrimitive.CloseButton class='absolute top-4 right-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-hidden focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              class='size-4'
            >
              <path d='M18 6l-12 12' />
              <path d='M6 6l12 12' />
            </svg>
            <span class='sr-only'>Close</span>
          </SheetPrimitive.CloseButton>
        </Show>
      </SheetPrimitive.Content>
    </SheetPortal>
  );
};

const SheetHeader: Component<ComponentProps<'div'>> = (props) => {
  const [local, others] = splitProps(props, ['class']);
  return (
    <div class={cn('flex flex-col space-y-2 text-center sm:text-left', local.class)} {...others} />
  );
};

const SheetFooter: Component<ComponentProps<'div'>> = (props) => {
  const [local, others] = splitProps(props, ['class']);
  return (
    <div
      class={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', local.class)}
      {...others}
    />
  );
};

type DialogTitleProps<T extends ValidComponent = 'h2'> = SheetPrimitive.DialogTitleProps<T> & {
  class?: string | undefined;
};

const SheetTitle = <T extends ValidComponent = 'h2'>(
  props: PolymorphicProps<T, DialogTitleProps<T>>,
) => {
  const [local, others] = splitProps(props as DialogTitleProps, ['class']);
  return (
    <SheetPrimitive.Title
      class={cn('font-semibold text-foreground text-lg', local.class)}
      {...others}
    />
  );
};

type DialogDescriptionProps<T extends ValidComponent = 'p'> =
  SheetPrimitive.DialogDescriptionProps<T> & { class?: string | undefined };

const SheetDescription = <T extends ValidComponent = 'p'>(
  props: PolymorphicProps<T, DialogDescriptionProps<T>>,
) => {
  const [local, others] = splitProps(props as DialogDescriptionProps, ['class']);
  return (
    <SheetPrimitive.Description
      class={cn('text-muted-foreground text-sm', local.class)}
      {...others}
    />
  );
};

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
};
